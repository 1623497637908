import React, {useContext, useEffect, useRef, useState} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faExpandAlt} from "@fortawesome/free-solid-svg-icons";
import SectionOurWork from "../components/SectionOurWork";
import SectionCareers from "../components/SectionCareers";
import SectionAbout from "../components/SectionAbout";
import SectionClients from "../components/SectionClients";
import SectionFooter from "../components/SectionFooter";
import {AppContext} from "../context/AppContext";
import SectionContact from "../components/SectionContact";

export const VideoOverlay = ({showNavbar = true}) => {

    const getCurrentTop = () => {
        return (-window.scrollY * .9) + 'px'
    }

    const [splashLoaded, setSplashLoaded] = useState(false)

    useEffect(() => {
        setTimeout(() => setSplashLoaded(true), 1000)
    }, []);

    const opacityVal = Math.max(0, 1 - (((window.scrollY - (window.innerHeight / 2))) + 450) / 1000)
    const videoRef = useRef()

    return <div className={'fixed h-screen w-screen z-0'}
                style={{top: getCurrentTop()}}>


        <div className={'h-screen w-[103.5vw] shadow-2xl rounded group top-10 -left-[calc(2vw)] z-10 overflow-hidden fixed duration-200 ' +
            (window.scrollY > 200 ? '' : '  ')}
             style={{
                 display: window.scrollY > window.innerHeight * 3 ? 'none' : '',
                 marginTop: splashLoaded ? 10 : -window.scrollY / 7,
                 scale: splashLoaded ? '90%' : '100%',
                 opacity: opacityVal,
             }}>
            <FontAwesomeIcon icon={faExpandAlt}
                             className={'absolute bg-black cursor-pointer top-0 text-2xl lg:text-3xl mix-blend-exclusion group-hover:scale-150 duration-100  ' +
                                 ' group-hover:opacity-50 text-white opacity-20 right-0 p-3 rounded-bl z-50 hover:drop-shadow-md drop-shadow group-hover:pr-5 group-hover:pt-5 hover:!opacity-100 ' +
                                 (splashLoaded ? '' : ' !   opacity-0')}
                             onClick={() => {
                                 videoRef.current?.requestFullscreen()
                                 console.log('ok')
                             }}/>
        </div>
        <video src="/showreel.mp4"
               muted={true}
               tabIndex={0}
               className={'md:block hidden object-cover h-full overflow-hidden grayscale fixed z-0 dark:opacity-20 md:blur-3xl ' +
                   'invert opacity-15 top-0 -left-0 scale-150 w-screen'}
               autoPlay={true}
               onLoadedDataCapture={(e) => {
                   console.log(e)
               }}
               loop={true}/>
        <div
            className={'h-screen w-[103.5vw] shadow-2xl duration-500 ease-out rounded -left-[calc(2vw)] overflow-hidden fixed  ' +
                (window.scrollY > 200 ? '' : '  ') +
                (splashLoaded ? ' top-10 ' : ' top-0')}
            style={{
                display: window.scrollY > window.innerHeight * 3 ? 'none' : '',
                marginTop: splashLoaded ? 10 : -window.scrollY / 7,
                scale: splashLoaded ? '90%' : '100%',
                opacity: opacityVal,
            }}>
            <div className={''}>

            </div>
            <video src="/showreel.mp4"
                   muted={true}
                   ref={videoRef}
                   tabIndex={0}
                   className={'object-cover h-full overflow-hidden top-0 absolute z-0 left-0 w-full'}
                   autoPlay={true}
                   loop={true}/>
            {showNavbar ?
                <div
                    className={'z-10 fixed bottom-10 drop-shadow-xl left-[calc(50%-30px)] animate-slowBounce text-white mix-blend-exclusion '}>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={'text-8xl animate-pulse'}/>
                </div> : <></>}
        </div>
    </div>
}

export default function App() {
    const [scrollY, setScrollY] = useState(window.scrollY ?? 0)

    useEffect(() => {
        const updateScrollY = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', updateScrollY)
        return () => window.removeEventListener('scroll', updateScrollY)
    }, []);

    const {setShowNavbar, showNavbar} = useContext(AppContext)
    const ref = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        if (window.scrollY > videoRef.current?.clientHeight) {
            setShowNavbar(true)
        }
    }, [setShowNavbar])


    return <div className={''} id={'ro'}>

        <div ref={ref} className={'mx-auto bg-blue-500/5 overflow-hidden'}>
            <div ref={videoRef} className={'relative z-0 min-h-screen snap-start max-h-[100vh]'}>
                <VideoOverlay setShowNavbar={setShowNavbar} showNavbar={showNavbar}/>
            </div>

            <div id="about"
                 className={'duration-300 z-0 relative'}>
                <SectionAbout/>
            </div>


            <div id="work"
                 className={'min-h-screen relative z-30 bg-slate-600/20'}>
                <SectionOurWork _ref={ref}/>
            </div>


            <div id="clients"
                 className={'min-h-screen relative'}>
                <SectionClients/>
            </div>

            <div id="careers"
                 className={'bg-sky-900/10 min-h-screen relative z-10'}>
                <SectionCareers/>
            </div>

            <div className={'min-h-screen relative'} id={'contact'}>
                <SectionContact/>
            </div>
            <div id="footer"
                 className={'relative'}>
                <SectionFooter/>
            </div>
        </div>
    </div>

}