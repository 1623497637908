import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faEnvelopeOpen} from "@fortawesome/free-regular-svg-icons";
import TextArea from "./Form/TextArea";
import Input from "./Form/Input";
import PrimaryButton from "./PrimaryButton";
import axios from "axios";
import {faMultiply} from "@fortawesome/free-solid-svg-icons";
import {Transition} from "@headlessui/react";

export default function SectionContact() {

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({
        name: null,
        email: null,
        message: null,
        response: null,
    })
    const [responseText, setResponseText] = useState('')
    const [data, setData] = useState({
        name: '',
        email: '',
        message: ''
    })

    const contactObjects = [{
        title: 'Beirut',
        phone: '+961 1 496286',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.\nDoloribus eligendi inventore non quam?\n\nAb, animi aspernatur culpa cum eaque fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.',
        style: 'bg-cyan-100/10',
    }, {
        title: 'Dubai',
        phone: '+971 4 445 8383',
        body: 'fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.',
        style: 'bg-cyan-100/15',
    }, {
        title: 'Jeddah',
        phone: '+966 12 606 2207',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus eligendi inventore non quam? Ab, animi aspernatur culpa cum eaque fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.',
        style: 'bg-cyan-100/20',
    }, {
        title: 'Riyadh',
        phone: '+966 11 490 3758',
        body: "Lorem ipsum doxulpa cum eaque fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.",
        style: 'bg-cyan-100/25',
    }]

    const onChange = (e) => setData({...data, [e.target.name]: e.target.value})

    const submit = (e) => {
        e.preventDefault()
        setErrors([])
        setResponseText('')
        console.log(errors)
        setIsLoading(true)

        let _err = errors;

        if (!data.name.length) {
            _err.name = 'Name is Required'
        }

        if (!data.email.length) {
            _err.email = ('E-mail is Required')
        }

        if (!data.message.length) {
            _err.message = ('A message is Required')
        }

        if (_err.length) {
            setErrors(_err)
            setIsLoading(false)
            return false
        }

        setIsLoading(true)


        axios.post(process.env.REACT_APP_API_URL + '/api/contact', {}).then(d => {
            setResponseText(d.data)
        }).catch(c => {

        }).finally(() => setIsLoading(false))
    }
    const ContactBlock = ({contact}) => <div className={'mx-auto hover:bg-blue-700 transition-all hover:text-white cursor-pointer'}>
        <span className={'xl:text-end font-black text-5xl'}>{contact.title}</span>
        <span className={'mb-10 font-thin block dark:text-white text-2xl'}>{contact.phone}</span>
        <img src={'/mf-b.png'}/>
    </div>


    return <div className={'min-h-screen place-content-center'}>
        <div className={'flex flex-wrap mx-auto'}>
            <div
                className="w-full min-h-screen xl:min-h-min xl:w-1/2 content-center text-center xl:text-end px-20">
                {contactObjects.map(c => <div key={c.title} className={'w-full py-2'}>
                    <ContactBlock contact={c}/>
                </div>)}
            </div>
            <div
                className="w-full min-h-screen xl:min-h-min py-28 xl:w-1/2 dark:bg-blue-700/5 bg-white dark:bg-black h-full content-center shadow-sm relative">
                <FontAwesomeIcon icon={faEnvelopeOpen} className={'text-[140px] opacity-10 select-none absolute top-72 left-14'}/>

                <form className={'sm:p-20 px-5'} onSubmit={submit}>

                    <Transition show={!!errors.length}
                                enter={'duration-500 p-10 bg-red-500'}
                                enterFrom={'opacity-0'}
                                enterTo={'opacity-100'}
                                leave={'duration-500'}
                                leaveFrom={'opacity-100'}
                                leaveTo={'opacity-0'}>

                        {errors.length ?
                            <div
                                className={'select-none bg-red-400 py-1 px-7 rounded shadow mb-4 relative'}>{Object.values(errors).map(err =>
                                <li className={'p-2 list-none'}>
                                    {err}
                                </li>)}
                                <FontAwesomeIcon icon={faMultiply} onClick={() => setErrors([])}
                                                 className={'cursor-pointer absolute top-4 right-5'}/>
                            </div> : <></>}
                    </Transition>

                    {responseText ? <div className={'p-2 select-none bg-lime-700 rounded my-10'}>
                        {responseText}
                    </div> : <></>}

                    <div className={'sd'}>

                        <h5 className={'sh !text-start font-extrabold text-5xl'}>
                            We'd love to hear from you
                        </h5>
                        <a href={'mailto:contact@aga-adk.com'} className={'inline-block underline font-black text-3xl mb-10'}>
                            contact@aga-adk.com
                            <FontAwesomeIcon className={'ml-5'} icon={faEnvelope}/>
                        </a>
                        <p className={'text-xl'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad consequuntur delectus,
                            deleniti est hic laboriosam
                            praesentium quae saepe. Accusantium atque cum excepturi iure, provident suscipit ut? Aspernatur autem nihil
                            vero!
                        </p>
                    </div>
                    <Input name={'name'} onChange={onChange} disabled={!!isLoading} placeholder={'Name'}/>
                    <Input name={'email'} onChange={onChange} disabled={!!isLoading} placeholder={'E-mail'}/>
                    <TextArea name={'message'} onChange={onChange} disabled={!!isLoading} placeholder={'Your message'}/>
                    <hr className={'mt-10 mb-5 dark:border-neutral-500'}/>
                    <PrimaryButton loading={!!isLoading} className={'mt-5 !text-xl w-full py-5 text-center'}>Send</PrimaryButton>
                </form>
            </div>
        </div>
    </div>
}
