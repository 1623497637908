import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faCircle, faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import {AppContext} from "../context/AppContext";

export default function SectionFooter() {
    const {darkMode, toggleDarkMode} = useContext(AppContext)

    const socialIconClass = 'text-3xl dark:opacity-50 hover:text-sky-600 hover:scale-110 dark:hover:opacity-100 hover:opacity-100 transition-all'

    const SocialBox = ({className}) => <>
        <div
            className={'flex w-full text-center justify-center xl:justify-start gap-x-5 transition-all duration-1000 items-center text-3xl'
                + (className ? ' ' + className : '')}>
            <a href={'https://www.facebook.com/AGAADK'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faFacebookSquare}/>
            </a>
            <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
            <a href={'https://www.instagram.com/agaadkmena'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faInstagram}/>
            </a>
            <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
            <a href={'https://www.linkedin.com/company/aga-adk-advertising-&-marketing/'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </a>
            <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
            <a href={'https://www.youtube.com/@aga-adk1383'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faYoutube}/>
            </a>
        </div>
    </>

    return <div className={'pb-10 backdrop-blur-2xl bg-sky-900 text-white dark:bg-black/30'}>

        <div className={'py-5 flex sm:px-20 flex-wrap items-stretch md:justify-between'}>

            <div className={'w-full lg:w-1/4 flex items-center justify-center xl:justify-start flex-wrap '}>
                <SocialBox/>
            </div>
            <div
                className={'w-full gap-y-10 flex-wrap my-10 lg:w-1/2 flex items-end justify-between lg:justify-end'}>
                <img className={'w-[160px] max-w-[30%] h-10 invert px-3'} src="/pencell.svg" alt=""/>
                <img className={'w-[200px] max-w-[30%] h-10 invert px-5'} src="/equation.svg" alt=""/>
                <img className={'w-[200px] max-w-[30%] h-10 invert px-4 mr-3'} src="/wetpaint.svg" alt=""/>
            </div>

        </div>

        <div className={'mx-auto w-full justify-end'}>
            <div className={'w-full text-center lg:text-end mx-auto sm:px-28 whitespace-nowrap text-sm select-none space-x-3'}>
                <span>&copy; AGA AGK {new Date().getFullYear()} - All Rights Reserved</span>
                <div className={'text-center text-lg mx-auto cursor-pointer transition-all inline-block' +
                    (darkMode ? '' : ' hover:text-blue-200')}
                     onClick={() => toggleDarkMode()}>
                    <FontAwesomeIcon icon={darkMode ? faSun : faMoon}/>
                </div>
            </div>
        </div>

    </div>
}
