import React, {useEffect, useState} from 'react';
import {faBars, faChevronCircleUp, faChevronUp, faFileContract, faHome, faMultiply} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Transition} from "@headlessui/react";
import {faFacebookSquare, faInstagram, faLinkedin, faYoutubeSquare} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import defaultTheme from "tailwindcss/defaultTheme";

export default function Navbar() {

    const [sidebarOpened, setSidebarOpened] = useState(false);
    const NavButton = ({className, title, href, onClick}) => {

        const linkClass = 'cursor-pointer dark:hover:bg-sky-700 dark:hover:text-white ' +
            'hover:bg-orange-600 hover:text-black inline-block hover:!pb-7 hover:pt-24 duration-500 ' +
            'z-10 px-7 pt-7 hover:-mt-16 text-center group relative'

        return <>
            {href ? <Link to={href} className={linkClass} onClick={onClick}>
                <span className={'' + (className ? ' ' + className : '')}>{title}</span>
            </Link> : <span className={linkClass} onClick={onClick}>{title}</span>}
        </>
    }

    useEffect(() => {
        const checkScreen = () => {
            if (window.innerWidth > parseInt(defaultTheme.screens.xl)) {
                setSidebarOpened(false)
            }
        }
        window.addEventListener('resize', checkScreen);
        return () => window.removeEventListener('resize', checkScreen)
    }, []);

    const SidebarMenuLink = ({className, text, href}) =>
        <>
            <Link
                className={'hover:scale-105 mx-auto py-5 block font-light justify-center ' +
                    'dark:hover:bg-blue-900 dark:text-neutral-200 dark:hover:text-white ' +
                    'hover:bg-sky-700 hover:text-white duration-300 hover:rounded-sm w-full text-center hover:fonts-black hover:scale-125' +
                    (className ? ' ' + className : '')}
                to={href}>
                {text}
            </Link>
        </>

    return <>
        <div className={'fixed top-0 left-0 w-screen z-50 mix-blend-exclusion py-2 text-white'}>
            <nav className={'px-4 xl:px-14 flex items-start group justify-between'}>

                <div className={'flex items-center justify-start'}>
                    <Link to={'/'}
                          className={'relative sm:ml-3 z-50 invert font-black text-2xl select-none cursor-pointer'}
                          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'}) || setSidebarOpened(false)}>
                        <img src={'/logo.svg'}
                             className={'block w-28 lg:w-48 xl:w-64 invert mt-0.5 xl:mt-1 duration-1000 py-3 relative z-50'}
                             alt={'AGA ADK'}/>
                    </Link></div>

                <div className={'relative text-2xl hidden select-none xl:flex items-start mt-1'}>

                    <NavButton icon={faFileContract} title={'About'}
                               onClick={() => document.getElementById('about').scrollIntoView({behavior: 'smooth'})}
                    />
                    <NavButton icon={faFileContract} title={'Our Work'}
                               onClick={() => document.getElementById('work').scrollIntoView({behavior: 'smooth'})}/>
                    <NavButton icon={faFileContract} title={'Career'}
                               onClick={() => document.getElementById('careers').scrollIntoView({behavior: 'smooth'})}/>
                    <NavButton icon={faFileContract} title={'Contact Us'}
                               onClick={() => document.getElementById('contact').scrollIntoView({behavior: 'smooth'})}/>
                    <NavButton icon={faFileContract} title={'News'}
                               onClick={() => document.getElementById('about').scrollIntoView({behavior: 'smooth'})}/>

                </div>

                <FontAwesomeIcon
                    className={'xl:hidden focus-within:!outline-0 focus:!ring-none focus-visible:!ring-0 ring-0 active:!border-1 ' +
                        'z-10 mt-2 sm:mt-3 md:mt-4 mr-2 hover:scale-125 aspect-square' +
                        ' duration-100 ring cursor-pointer ' +
                        (sidebarOpened
                            ? ' -rotate-90 text-xl p-4 rounded-full shadow-xl ring-2 ring-white '
                            : ' ring-transparent sm:text-3xl lg:text-4xl xl:text-5xl text-3xl')}
                    tabIndex={1}
                    onClick={() => setSidebarOpened(!sidebarOpened)}
                    icon={sidebarOpened ? faMultiply : faBars}/>
            </nav>
        </div>
        <Transition show={!!sidebarOpened}
                    enter={'duration-200 '}
                    enterFrom={'opacity-0 -mt-[100vh]'}
                    enterTo={'opacity-100 mt-0'}
                    leave={'duration-200'}
                    leaveFrom={'-mr-[100vw] -top-0 opacity-100'}
                    beforeEnter={() => setSidebarOpened(true)}
                    beforeLeave={() => setSidebarOpened(false)}
                    leaveTo={'-mr-[100vw] opacity-0'}>
            <div
                className={'fixed py-32 z-40 px-5 font-semibold flex items-center top-0 right-0 bg-white/50 dark:bg-black/80 backdrop-blur-2xl ' +
                    'dark:text-white w-screen h-screen'}>
                <div className={'text-4xl h-screen place-content-around w-screen'}>
                    <div className={'w-min mx-auto overflow-hidden flex flex-wrap h-full contents-around'}>
                        <div className={'w-full content-end'}>
                            <div className={'duration-500 delay-200' + (sidebarOpened ? ' scale-150 opacity-100 ' : ' opacity-0')}>
                                <SidebarMenuLink className={'mb-12'}
                                                 text={<FontAwesomeIcon icon={faHome}/>}
                                                 href={'/'}/>
                            </div>
                            <SidebarMenuLink text={'About'}/>

                            <SidebarMenuLink text={'Work'}/>
                            <SidebarMenuLink text={'News'} href={'/news'}/>
                            <SidebarMenuLink text={'Career'}/>
                        </div>

                        <div className={'w-full text-5xl flex items-center justify-center'}>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faFacebookSquare}/>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faInstagram}/>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faLinkedin}/>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faYoutubeSquare}/>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </>
}
