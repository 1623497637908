import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import PageContainer from "../../components/PageContainer";

export default function WorkSingle() {
    const {id} = useParams();

    const [loadedProject, setLoadedProject] = React.useState({
        id: null,
        title: 'Sample Projects',
        description: null,
    });


    const elementRef = useRef()
    const minLineHeight = 1
    const maxHeight = 1
    const [lineHeight, setLineHeight] = useState(minLineHeight);
    useEffect(() => {
        const handleScroll = () => {
            const newScrollPercent = (window.scrollY / (document.scrollingElement.scrollHeight - window.innerHeight)) * 100;
            const newLineHeight = Math.max(1, newScrollPercent * .015);

            setLineHeight(newLineHeight);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);

        };
    }, [minLineHeight, maxHeight]);


    return <PageContainer title={loadedProject.title} heading={
        <video src={'/showreel.mp4'} autoPlay muted loop className={'w-screen'}/>}>

        <div>
            <article>
                <p>


                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla viverra ligula, non tincidunt orci convallis
                    dictum. In hac habitasse platea dictumst. Nunc dapibus sagittis mi vitae varius. Duis malesuada, neque quis placerat
                    elementum, nisl nulla accumsan quam, ut volutpat erat ante ut urna. Morbi nec nisi pretium, dignissim ex eu, euismod
                    orci. Vestibulum nec felis eros. Maecenas eu congue nulla. Phasellus egestas non odio non euismod. Etiam ac ligula
                    dapibus, ultricies risus eget, feugiat augue. In hac habitasse platea dictumst. Sed porttitor a tellus quis dictum.
                    Nullam sed posuere mauris. Duis scelerisque ultrices dolor sed venenatis. Nam arcu sapien, pulvinar in euismod id,
                    ornare id felis.

                    Sed egestas finibus mi a volutpat. Morbi iaculis ultricies libero, vel dapibus mauris cursus eu. Etiam vehicula
                    vestibulum consectetur. Maecenas condimentum lacus vel sem auctor imperdiet. Praesent placerat turpis non tincidunt
                    semper. Cras orci lorem, suscipit quis bibendum vitae, condimentum non augue. In consequat pharetra risus nec bibendum.
                    Duis ac lectus diam. Sed suscipit urna quis sem condimentum convallis. Maecenas viverra efficitur ex, nec elementum
                    felis dignissim ut. Aenean feugiat quam eu tortor hendrerit sagittis. Aenean dignissim viverra dignissim. Curabitur nec
                    est nec est condimentum tempor sed at dui. Aliquam suscipit aliquam aliquet.

                    Donec ullamcorper consequat magna ut aliquet. Etiam eros est, congue vel mattis vitae, laoreet sit amet neque. In ut leo
                    risus. Etiam laoreet est sit amet erat volutpat rhoncus. Donec molestie, nunc ut sagittis aliquam, nisi lacus porttitor
                    ante, eu lacinia lacus leo vitae dolor. Sed convallis semper neque, at luctus enim venenatis id. Quisque lacus nisl,
                    tristique vitae ornare et, tincidunt non ligula. Quisque maximus sollicitudin dui, pulvinar dapibus leo consequat
                    lacinia. In bibendum fringilla lectus, id convallis orci pulvinar vel. Mauris auctor, felis et tempor viverra, orci
                    lorem dignissim nulla, et euismod sapien velit nec nunc. Curabitur auctor urna nec justo blandit, vitae fringilla eros
                    tempor. Etiam sed volutpat augue. Donec blandit ex eu tortor efficitur tempus. Duis vitae augue consectetur, placerat
                    dolor at, cursus nisi. Integer congue pharetra diam ac sollicitudin.

                    Praesent ut tincidunt eros. Fusce gravida ex sapien, gravida suscipit tortor egestas vitae. Nam cursus ante sit amet
                    elementum tempor. Vestibulum ac est tristique, ultricies risus et, finibus diam. Nunc dui sapien, vestibulum et laoreet
                    in, hendrerit at quam. Sed finibus neque a dui iaculis, ac gravida lectus sollicitudin. Integer pellentesque nisi a
                    efficitur tempor. Sed purus nisl, pretium in rhoncus id, iaculis at enim. Sed aliquam mattis ex et varius. Aenean
                    aliquet accumsan erat a iaculis. Sed quam purus, porttitor id mi eu, luctus tincidunt risus. Fusce gravida neque sit
                    amet ultrices lobortis. Mauris ultricies, dolor a dapibus consectetur, libero dui hendrerit massa, vehicula malesuada
                    arcu elit sed mi. Sed at diam semper, consectetur velit ac, fermentum magna.

                    Vivamus sed risus gravida mauris rhoncus tincidunt. Pellentesque congue nisl in dui tincidunt, ac hendrerit libero
                    feugiat. In a tortor lacinia, accumsan sem sit amet, suscipit ligula. Sed sollicitudin felis iaculis, dictum felis eu,
                    fermentum ante. Fusce eu elit sem. Cras sit amet turpis in ligula aliquet suscipit sed sed nisi. Pellentesque
                    consectetur risus eu commodo condimentum. Mauris volutpat lobortis quam, sed convallis arcu venenatis id.
                </p>
            </article>
        </div>
    </PageContainer>
}