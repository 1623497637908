import React from 'react';

export const AboutBlock = ({className, offsetTop, title, children}) => <div
    className={'leading-5 4xl:leading-none bg-sky-900/5 rounded dark:bg-black/10 p-10 relative ' +
        (className ? ' ' + className : '')}
    style={{marginTop: -offsetTop}}>
    <h2 className={'inline text-4xl 2xl:text-5xl lg:text-6xl mt-0 mb-3 select-none font-bold'}>{title}</h2>
    <div className={'inline py-2 pl-2 text-lg lg:text-xl font-thin 4xl:text-2xl'}>{children}</div>
</div>

export default function SectionAbout() {

    return <div className={'lg:px-4 z-10 text-2xl mx-auto flex overflow-hidden py-20 relative flex-wrap xl:px-10 sm:px-1'}>
        <div className={'items-center flex flex-wrap justify-center relative min-h-[200vh] max-w-screen ' +
            'mx-auto xl:px-5'}>

            <h1 className={'px-7 leading-normal text-white text-[15vw] xl:text-9xl ' +
                ' 3xl:text-[200px] 4xl:text-[300px] animate-superExtraSlowBounce text-start dark:text-sky-500 text-black w-full font-thin'}
                style={{lineHeight: .4}}>
                <span className={'opacity-80 text-black 4xl:-top-40 dark:text-white left-0 absolute -top-10 inline-block scale-75'}
                      style={{marginTop: (window.scrollY - (window.innerHeight / 10 + 600)) * .1}}>We are</span>
                <strong
                    className={'z-10 relative py-12 ml-20 block dark:mix-blend-normal text-sky-500 whitespace-nowrap dark:text-sky-700 drop-shadow-xl font-black'}
                    style={{marginTop: (window.scrollY - (window.innerHeight / 4 + 600)) * .2}}>AGA ADK</strong>

                <strong
                    className={'absolute top-20 opacity-30 animate-extraSlowBounce blur-3xl left-0 py-12 ml-20 block dark:mix-blend-normal text-sky-500 whitespace-nowrap dark:text-sky-300 drop-shadow-xl font-black'}
                    style={{marginTop: (window.scrollY - (window.innerHeight / 4 + 600)) * .06}}>AGA ADK</strong>

                <span className={'font-thin block text-black dark:text-white text-xl 2xl:text-4xl max-w-7xl px-3 xl:px-0 text-justify'}
                     style={{marginTop: (window.scrollY - (window.innerHeight / 4 + 700)) * .12}}>
                    <strong className={'font-bold'}>An independent marketing communications and creative agency</strong> — <strong
                    className={'font-bold'}>fueled</strong> by
                    our constant quest for novel thinking. We
                    believe the universe is made out of <strong className={'font-bold'}>stories</strong>; and as such we work
                    with our brand partners in crafting their
                    stories through <strong className={'font-bold'}>campaigns</strong> that create meaningful connections with their <strong
                    className={'font-bold'}>audience</strong>.
                    <div className={'relative'}>
                        <hr className={'absolute top-0 left-0 mt-4 border-0 h-0.5 glow rounded bg-gradient-to-r  ease-out to-sky-500 from-transparent'}
                            style={{
                                width: Math.max(0, -(((-window.scrollY + window.innerHeight - 30) * 5)) * .15) + '%',
                                maxWidth: '100%',
                            }}/>
                    </div>
                </span>
            </h1>
            <div className={'flex flex-wrap container mx-auto items-start mt-72 content-evenly'}>
                <div className={'w-full z-30 flex flex-wrap items-center'}>
                    <AboutBlock offsetTop={(-window.scrollY + 1000) * .109} title={'history'}
                                className={'xl:w-full 2xl:w-1/3 text-justify animate-extraSlowBounce w-full '}>
                        <div className={'w-96 aspect-square -mt-80 left-0 absolute bg-contain mix-blend-exclusion bg-transparent'}
                             style={{
                                 backgroundColor: 'transparent',
                                 backgroundImage: 'url(/tumblr_nhdcj12Kmc1u4kyopo1_1280-ezgif.com-effects.gif)'
                             }}>
                        </div>
                        Founded in 1997, we started as AGA – Arabian Gulf Advertising & Marketing, a full-service agency, dedicated to help
                        brands
                        create memorable campaigns in the Middle East through creative bravery, strategic planning, media solutions, PR,
                        branding
                        and physical experiences. Through perseverance and ambition, we grew out of Dubai and became present across the
                        region
                        in
                        Lebanon, Jordan, Egypt and KSA. Our achievements led to us signing an affiliation agreement with ADK, Japan’s third
                        largest
                        agency Network with 50 offices around the world, and thus our new journey as AGA-ADK began. Today, we are part of a
                        larger
                        holding company known as The Network Communication Group - TNCG that provides holistic integrated marketing
                        communication
                        solutions in the MENA region, all under one roof.
                    </AboutBlock>
                    <AboutBlock className={'w-full xl:w-1/2 2xl:w-1/3 top-32 lg:text-center md:top-32 4xl:top-40 lg:top-10 sm:top-24'}
                                offsetTop={(((window.scrollY - 3400) - window.innerHeight) + 1600) / 7}
                                title={'mission'}>
                        <div
                            className={'w-96 aspect-square -bottom-10 -mt-[-240px] bg-contain bg-no-repeat right-20 absolute mix-blend-exclusion bg-transparent'}
                            style={{
                                backgroundColor: 'transparent',
                                backgroundImage: 'url(/03aa3cc669054981e2ef1a8f1bc85022-ezgif.com-gif-maker.gif)'
                            }}>
                        </div>
                        We exist to create meaningful connections between brands and consumers in an ever evolving world driven by mobile
                        interaction
                        and physical experiences.
                    </AboutBlock>
                    <AboutBlock
                        className={'w-full xl:w-1/2 2xl:w-1/3 top-5 z-30 lg:-top-36 xl:top-32 md:top-16 md:text-end animate-extraSlowBounce ease-out'}
                        offsetTop={((window.scrollY - window.innerHeight) - 3000) / 5}
                        title={'vision'}>
                        <div
                            className={'w-[1300px] -left-[80%]  -top-[40px] bg-contain bg-no-repeat aspect-square -mt-[300px] absolute mix-blend-exclusion bg-transparent'}
                            style={{
                                backgroundColor: 'transparent',
                                backgroundImage: 'url(/da967073116977.5bfecec84fe10-ezgif.com-gif-maker.gif)'
                            }}>
                        </div>
                        We strive to deliver evermore memorable campaigns to our brand partners by bringing forward fully integrated
                        marketing
                        communication solutions that go beyond the now.
                    </AboutBlock>

                </div>
            </div>
        </div>
    </div>
}
