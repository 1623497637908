import React, {useEffect, useState} from 'react';
import SectionFooter from "./SectionFooter";
import {Helmet} from "react-helmet";

function PageContainer({title, children, helmet = {title: title, description: null}, heading}) {

    const [careers, setCareers] = useState([]);

    useEffect(() => {
        //setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 50)
    }, []);

    return <div className={'px-5 dark:bg-black min-h-[100vh] pt-5 -mt-36'}>
        <Helmet>
            <title>{helmet.title}</title>
        </Helmet>
        <div className={'mt-20 -mx-5'}>
            <div className={''}>
                {heading}
            </div>
        </div>
        <div className={'min-h-screen  flex items-center -mx-5'}>
            <div className={'container mx-auto py-20 px-10'}>
                <h1 className={'text-start text-7xl'}>{title}</h1>
                <hr className={'my-20'}/>
                <div className={'text-2xl'} style={{
                    lineHeight: Math.max(1.2, window.scrollY /1000),
                }}>{children}</div>
            </div>
        </div>

        <div className={'px-5 mx-auto'}>
            <div className={'-mx-10'}><SectionFooter/></div>
        </div>
    </div>
}

export default PageContainer;
