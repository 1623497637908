import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import NoMatch from "./pages/NoMatch";
import {AppContext} from "./context/AppContext";
import Navbar from "./components/Navbar";
import CareersIndex from "./pages/Career/CareersIndex";
import WorkIndex from "./pages/Work/WorkIndex";
import WorkSingle from "./pages/Work/WorkSingle";
import CareersSingle from "./pages/Career/CareerSingle";

const root = ReactDOM.createRoot(document.getElementById('root'));

const Wrapper = ({children}) => {
    const [darkMode, setDarkMode] = useState(0)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [showNavbar, setShowNavbar] = useState(true);

    const toggleDarkMode = () => {
        // toggle Dark mode 0|1
        setDarkMode(!darkMode)

        // toggle Dark mode className on|off
        darkMode ? document.documentElement.classList.remove('dark')
            : document.documentElement.classList.add('dark')

        // toggle storage theme
        localStorage.theme = darkMode ? 'light' : 'dark'
    }

    const toggleSidebarOpen = () => {
        setSidebarOpen(!sidebarOpen)
    }

    const fn = {
        darkMode: darkMode,
        setDarkMode: setDarkMode,
        toggleDarkMode: toggleDarkMode,
        sidebarOpen: sidebarOpen,
        setSidebarOpen: setSidebarOpen,
        toggleSidebarOpen: toggleSidebarOpen,
        showNavbar: showNavbar,
        setShowNavbar: setShowNavbar,
    }

    useEffect(prev => {

        if ((localStorage.theme === 'dark' ||
            (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))) {

            document.documentElement.classList.add('dark')
            setDarkMode(1)
        } else {
            document.documentElement.classList.remove('dark')
            setDarkMode(0)
        }

    }, [])

    return <>
        <AppContext.Provider value={{...fn}}>
            <div className={'dark:bg-black bg-slate-100'}>
                <Navbar/>
                <div className={'pt-36'}>
                    <div>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </AppContext.Provider>
    </>
}
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppContext.Provider value={{}}>
                <Routes>
                    <Route path={'/'} element={<Wrapper/>}>
                        <Route index element={<App/>}/>
                        <Route path={'work'} element={<WorkIndex/>}/>
                        <Route path={'work/:id'} element={<WorkSingle/>}/>
                        <Route path={'careers'} element={<CareersIndex/>}/>
                        <Route path={'careers/:id'} element={<CareersSingle/>}/>
                        <Route path="*" element={<NoMatch/>}/>
                    </Route>
                </Routes>
            </AppContext.Provider>
        </BrowserRouter>
    </React.StrictMode>
);
reportWebVitals();
