import React from 'react';
import {Link} from "react-router-dom";

const work = [
    {
        title: 'Automotive',
        projects: [
            {
                title: 'Porsche KSA', thumb: '/65b8ecc32a5f9.png',
                link: '/work/ksa'
            },
            {
                title: 'Dunlop', thumb: '/66b37930d2a24.jpeg'
            },
        ]
    },
    {
        title: 'Consumer Goods',
        projects: [
            {title: "Chivas", thumb: '/65ae9493446a4.png'},
            {title: "Tabasco Worldcup", thumb: '/65ae9370de15c.png'},
            {title: "Always Night Series", thumb: '/65ae8d6f537aa.png'},
            {title: "Anghami Snackipies", thumb: '/65ae716b68189.jpeg'},
            {title: "Ramadan Campaign 2022", thumb: '/65ae7292304b1.png'},
        ]
    },
    {
        title: 'Consumer Electronics',
        projects: [
            {title: 'Bosch Capacity Campaign 2022', thumb: '/65ae9fa05ac97.png'}
        ]
    },
    {
        title: "Government & NGO",
        projects: [
            {title: 'Ou3a Case Study', thumb: '/65aea9ca128ab.png'}
        ]
    },
    {
        title: 'Toys',
        projects: [
            {title: 'Hasbro Gaming', thumb: '/65aeaba4239fc.png'}
        ]
    },
    {
        title: 'Pharma & Healthcare',
        projects: [
            {title: 'Vicks - Social Media Posts', thumb: '/65aead1201956.png'},
            {title: "Hershey's Ramadan Campaign 2022", thumb: '/65ae6fcee5d1a.png'},
        ]
    }
]

export const ProjectBlock = ({project, link, i}) => {

    const linkClassName = 'group hover:rounded-3xl block hover:z-10 z-0 relative overflow-hidden duration-500 shadow ' +
        'relative hover:z-1 cursor-default';

    return <>
        <Link to={link} className={linkClassName}>
            <div
                className={'aspect-video transition-all cursor-pointer'}>
                <div className={'w-full h-full overflow-hidden absolute top-0 left-0'}>
                    <img src={project.thumb}
                         className={'group-hover:scale-[1.1] duration-200 ease-in absolute flex items-center justify-center text-black ' +
                             'top-0 left-0 w-full h-full object-cover '} alt={project.title}/></div>
            </div>

            <div className={'flex flex-wrap duration-300 px-5 left-0 absolute bottom-0 z-10 text-xl text-white drop-shadow py-2'}>
                <span className={'w-full text-sm drop-shadow-lg transition-all'}>
                    {project.title}
                </span>
            </div>
        </Link>
    </>
}


export default function SectionOurWork({_ref}) {
    return <div className={'overflow-hidden mx-auto container py-40 my-40 group/mainContainer'}>
        <div
            className={'relative min-h-screen flex items-center content-center flex-wrap ml-0'}>
            <div className={'w-full px-3 text-center'}>
                <h2 className={'sh 3xl:text-9xl'}><span className={'font-light'}>Our</span> Work</h2>
                <p className={'sd animate-superExtraSlowBounce'}>
                    Over three decades, we've created numerous projects, here's a fraction of our total work. </p>
            </div>
            <div className={'flex flex-wrap z-30 w-full justify-center'}>{[
                ...work[0].projects,
                ...work[1].projects,
                ...work[2].projects,
                ...work[3].projects,
                ...work[4].projects,
                ...work[5].projects,
            ].map((w, i) =>
                <div key={w.title}
                     className={'owi relative select-none w-1/2 sm:w-1/3 duration-2000 ease-out md:w-1/4 lg:w-1/4 xl:w-1/4 3xl:w-1/6 p-2 4xl:w-1/6'}
                style={{marginTop:(-((window.scrollY/.6)-(window.innerHeight*5)))/(i+10)*2 > 0 ?(-((window.scrollY/.9)-(window.innerHeight*5)))/(i+100)*2 : 0}}>
                    <ProjectBlock project={w} link={w.link} i={i}/>
                </div>)}
            </div>
        </div>
    </div>
}
