import React from 'react';
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';

export default function SectionClients() {
    const clients = [
        {name: 'Anker', thumb: '/c/Anker.svg'},
        {name: 'Bank of Sharjah', thumb: '/c/Bank of Sharjah Logo-01.svg'},
        {name: 'Saudi Electricity Company', thumb: '/c/Saudi_Electricity_Company-01.svg'},
        {name: 'DCCI', thumb: '/c/DCCI logo.svg'},
        {name: 'Al Fardan Exchange', thumb: '/c/Al fardan exchange-01.svg'},
        {name: 'Dunlop', thumb: '/c/Dunlop-01.svg'},
        {name: 'UAE Pro League', thumb: '/c/jkd2g51643234984.png'},
        {name: 'Michael Kors', thumb: '/c/michael_kors-01.svg'},
        {name: 'Siemens', thumb: '/c/SIemens.svg'},
        {name: 'Bosch Logo', thumb: '/c/Bosch Logo 23.svg'},
        {name: 'Fidelity', thumb: '/c/fidelity.png'},
        {name: 'Mentos', thumb: '/c/mentos-logo-01.svg'},
        {name: 'Falken', thumb: '/c/Falken Logo-01.svg'},
        {name: 'Iffco', thumb: '/c/Iffco Logo-01.svg'},
        {name: 'BBAC', thumb: '/c/bbac.png'},
        {name: 'Lacnor', thumb: '/c/Lacnor Logo.svg'},
        {name: 'NorthwestBus', thumb: '/c/Northwestbuse Logo.svg'},
        {name: 'Protector and Gamble', thumb: '/c/Procter_and_Gamble-01.svg'},
        {name: 'RedBull', thumb: '/c/redbull-01.svg'},
        {name: 'GMG', thumb: '/c/gmg.svg'},
    ]

    const responsive = {
        0: {items: 2},
        700: {items: 3},
        1024: {items: 5},
        1400: {items: 6},
    };


    return <>
        <div className={'container relative mx-auto min-h-screen flex items-center flex-wrap content-center py-80'}>

            <div className={'w-full lg:px-0 px-5 xl:px-10 my-20'}>
                <div className={'relative'}>


                    <h2 className={'absolute -top-72 scale-115 animate-pulse blur-3xl dark:blur-2xl sh !text-transparent ' +
                        'font-light bg-gradient-to-r bg-clip-text from-sky-900 to bg-white/60'}>
                        <span className={'text-sky-700 font-black'}>Twenty seven</span> years of success
                    </h2>

                    <h2 className={'absolute -top-72 sh left-[0%] 2xl:left-[10%] 4xl:left-[16%] 3xl:left-[7.5%] !text-transparent' +
                        ' font-light bg-gradient-to-r bg-clip-text from-sky-900 to bg-white/60'}>
                        <span className={'dark:text-white text-sky-700 font-black'}>Twenty seven</span> years of success
                    </h2>

                </div>
            </div>


            <div className={'w-full flex items-center invert dark:invert-0 h-40'}>
                <AliceCarousel
                    infinite
                    responsive={responsive}
                    touchTracking
                    disableButtonsControls
                    autoPlay
                    mouseTracking
                    keyboardNavigation={false}
                    controlsStrategy={'alternate'}
                    autoPlayInterval={2000}
                    items={clients.map(c =>
                        <div className={'w-full text-center h-full'}>
                            <img alt={c.name} src={c.thumb} role={'presentation'}
                                 className={' aspect-video px-10 mx-auto'}/></div>)
                    }/>
            </div>
            <p className={'sd text-center py-4 mt-32 px-3 animate-superExtraSlowBounce'}>
                `At <strong className={'font-bold'}>AGA ADK</strong>, we believe that true success comes from strong partnerships. Our
                clients are more than just
                customers; they are collaborators who share our vision and drive our growth. Together, we've achieved remarkable things,
                and
                we're excited to see what the future holds. `
            </p>

        </div>
    </>
}
